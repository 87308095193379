<template>
<div>
    <div class="evaluation mt-5 cart-Page cart-Page-done" v-if="done == true">
        <div class="topboxorder">
            <div class="current-orders py-4">
                <div class="icons d-flex justify-content-center">
                    <b-icon icon="circle-fill" class="orange circle-icon h4"></b-icon>
                    <b-icon icon="dash" class="orange circle-icon"></b-icon>
                    <b-icon icon="dash-lg" class="orange circle-icon"></b-icon>
                    <b-icon icon="dash-lg" class="orange circle-icon"></b-icon>
                    <b-icon icon="dash-lg" class="orange circle-icon"></b-icon>
                    <b-icon icon="dash-lg" class="orange circle-icon"></b-icon>
                    <b-icon icon="dash" class="orange circle-icon"></b-icon>
                    <b-icon icon="circle-fill" class="orange circle-icon h4"></b-icon>
                    <b-icon icon="dash" class="orange circle-icon"></b-icon>
                    <b-icon icon="dash-lg" class="orange circle-icon"></b-icon>
                    <b-icon icon="dash-lg" class="orange circle-icon"></b-icon>
                    <b-icon icon="dash-lg" class="orange circle-icon"></b-icon>
                    <b-icon icon="dash-lg" class="orange circle-icon"></b-icon>
                    <b-icon icon="dash" class="orange circle-icon"></b-icon>
                    <b-icon icon="circle-fill" class="orange circle-icon h4"></b-icon>
                    <b-icon icon="dash" class="orange circle-icon"></b-icon>
                    <b-icon icon="dash-lg" class="orange circle-icon"></b-icon>
                    <b-icon icon="dash-lg" class="orange circle-icon"></b-icon>
                    <b-icon icon="dash-lg" class="orange circle-icon"></b-icon>
                    <b-icon icon="dash-lg" class="orange circle-icon"></b-icon>
                    <b-icon icon="dash" class="orange circle-icon"></b-icon>
                    <b-icon icon="circle-fill" class="pink circle-icon h4"></b-icon>
                </div>
                <div class="order-done d-flex justify-content-center pt-3">
                    <span class="mx-5"> {{ $t("shopping cart") }} </span>
                    <span class="mx-5"> {{ $t("shipping address") }} </span>
                    <span class="mx-5"> {{ $t("payment") }} </span>
                    <span class="mx-5">{{ $t("Submit a request") }} </span>
                </div>
            </div>
        </div>

        <div class="container items" v-if="done">
            <h4 class="mt-5">
                {{ $t("Submit the request") }}
            </h4>

            <div class="thanks my-5">
                <div class="right">
                    <b-icon icon="check2" class="check2"></b-icon>
                </div>
                <h5 class="my-5">
                    {{ $t("Thank you for your request") }}
                </h5>
                <p class="mb-5">
                    {{ $t("Your request has been submitted successfully!") }}
                </p>
                <router-link to="/" class="back mb-5">
                    {{ $t("Back to Home") }}
                </router-link>
                <router-link :to="'/orderDetails/' + id" class="request">
                    {{ $t("Your order details") }}
                </router-link>
            </div>
        </div>
        <div class="container items" v-else>
            <h4 class="mt-5">
                {{ $t("Submit the request") }}
            </h4>

            <div class="thanks my-5">
                <div class="right">
                    <i class="bi bi-x-circle" style="color: red"></i>
                </div>
            </div>
        </div>
    </div>
    <div  class="evaluation mt-5 cart-Page cart-Page-done" v-if="done == false">

        <div class="container items">
            <h4 class="mt-5">
                {{ $t("Submit the request") }}
            </h4>

            <div class="thanks my-5">
                <div class="right">
                    <b-icon style="width:55px;position:relative;margin-top:20px" icon="x-circle" scale="2" variant="danger"></b-icon>
                </div>
                <h5 class="my-5">
                    {{ $t("Thank you for your request") }}
                </h5>
                <p class="mb-5">
                    {{ $t("An error occurred while making the payment !") }}
                </p>
            </div>
        </div>
       
    </div>
  
</div>
</template>

<script>
export default {
    name: "sendRequest",
    data() {
        const lang = localStorage.lang || "en";
        return {
            lang,
            id: null,
            done: true,

        };
    },
    methods: {},
    created() {
        this.id = this.$route.params.id;

        let obj = {
        };

        if(this.$route.query.orderId){
           obj.tamaraId = this.$route.query.orderId;
        }
        this.$http.post("cart/bayDone/" + this.id,obj).then((res) => {
          
            this.done = res.data.success;
            this.$eventHub.$emit("updateCart", {});

        });
    },
};
</script>

<style></style>
